import React from 'react';
import Classnames from 'classnames';
import {
    convertCentsToDollars,
    currencyDisplay,
    formatDate,
    isNotDefined,
    isNumber,
    isString,
} from '../../../../utils';
import { ToolTip } from '../..';

import './info-item.scss';

export enum InfoItemType {
    TEXT = 'text',
    CURRENCY = 'currency',
    DATE = 'date',
    JSX = 'jsx',
}

export enum InfoItemSpacing {
    DENSE = 'dense',
    STANDARD = 'standard',
    SPARSE = 'sparse',
}

export enum InfoItemToolTipStatus {
    DEFAULT = 'default',
    SUCCESS = 'success',
    ERROR = 'error',
}

interface InfoItemProps {
    className?: string;
    currencyCode?: string;
    indent?: boolean;
    secondaryText?: boolean;
    spacing?: InfoItemSpacing;
    statusMessage?: string;
    statusShowing?: boolean;
    title: string;
    toolTipContent?: string;
    toolTipHeadline?: string;
    toolTipStatus?: InfoItemToolTipStatus;
    type: InfoItemType;
    useBorder?: boolean;
    useSeparator?: boolean;
}

export const InfoItem: React.FC<InfoItemProps> = ({
    className = '',
    currencyCode = 'USD',
    indent = false,
    secondaryText = false,
    spacing = InfoItemSpacing.STANDARD,
    statusMessage,
    statusShowing = false,
    title,
    toolTipContent,
    toolTipHeadline,
    toolTipStatus,
    type,
    useBorder = false,
    useSeparator = false,
    children,
}): React.ReactElement => {
    const classNames = Classnames(`info-item ${className}`, {
        'info-item--use-separator': useSeparator && !useBorder,
        'info-item--use-border': useBorder,
        'info-item--dense-spacing': spacing === InfoItemSpacing.DENSE,
        'info-item--sparse-spacing': spacing === InfoItemSpacing.SPARSE,
    });

    const toolTipClassnames = Classnames('info-item__tooltip', {
        'info-item__tooltip--is-success': toolTipStatus === InfoItemToolTipStatus.SUCCESS,
        'info-item__tooltip--is-error': toolTipStatus === InfoItemToolTipStatus.ERROR,
    });

    const valueClassnames = Classnames('info-item__value', {
        'info-item__value--indent': indent,
        'info-item__value--is-string': !InfoItemType.JSX,
        'info-item__value--is-secondary': secondaryText,
    });

    const statusClassnames = Classnames('info-item__status', {
        'info-item__status--showing': statusShowing,
    });

    let actualChild = children;

    if (isNotDefined(actualChild)) {
        actualChild = null;
    } else {
        switch (type) {
            case InfoItemType.JSX:
                break;

            case InfoItemType.TEXT:
                actualChild = <p>{actualChild}</p>;
                break;

            case InfoItemType.CURRENCY:
                if (!isNumber(children)) {
                    if (isString(children)) {
                        actualChild = parseInt(children);
                    } else {
                        actualChild = parseInt(children.toString());
                    }

                    if (isNaN(actualChild as number)) {
                        console.error(`Invalid currency value found: "${children}"`);
                        actualChild = null;
                        break;
                    }
                }

                actualChild = (
                    <p>
                        {currencyDisplay(currencyCode, parseFloat(convertCentsToDollars(actualChild as number)), true)}
                    </p>
                );
                break;

            case InfoItemType.DATE:
                if (!isString(children)) {
                    actualChild = actualChild.toString();
                }

                actualChild = <p>{formatDate(actualChild as string, false, true)}</p>;
                break;

            default:
                break;
        }
    }

    return (
        <div className={classNames}>
            <div className="info-item__header">
                <h2 className="info-item__title">{title}</h2>
                {!!toolTipContent && (
                    <div className={toolTipClassnames}>
                        <p>{toolTipHeadline}</p>
                        <ToolTip content={toolTipContent} modalHeadline={toolTipHeadline} />
                    </div>
                )}
                {!!statusMessage && <span className={statusClassnames}>{statusMessage}</span>}
            </div>
            <div className={valueClassnames}>{actualChild}</div>
        </div>
    );
};
