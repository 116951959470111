export enum PortalEventGroups {
    PortalLogin = 'admin-login',
}

export interface firebaseSignInRequest {
    idToken: string;
    displayName: string;
}
export interface firebaseSignInResponse {
    expirationDate: string;
}
