import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { basePortalQuery } from '../common';
import {} from './admin-portal-types';

export const portalApiSlice = createApi({
    reducerPath: 'adminPortalApi',
    baseQuery: basePortalQuery,
    tagTypes: [],
    endpoints: () => ({}),
});

export const {} = portalApiSlice;
