import { combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { PrizeoutEnv } from '../../utils/constants/prizeout-website';
import { commonInitState, commonSlices } from '../common';

import { portalApiSlice } from '../slices/admin-portal/admin-portal-api-slice';
import { portalAuthenticationApiSlice } from '../slices/admin-portal/admin-portal-authentication-api-slice';

export const adminPortalSlices = {};

export const adminPortalAPIQuerySlices = {
    [portalApiSlice.reducerPath]: portalApiSlice.reducer,
    [portalAuthenticationApiSlice.reducerPath]: portalAuthenticationApiSlice.reducer,
};

const adminPortalReducer = combineReducers({
    ...commonSlices,
    ...adminPortalSlices,
    ...adminPortalAPIQuerySlices,
});

const _adminPortalRootReducer = combineReducers({
    ...commonSlices,
    ...adminPortalSlices,
});

export const adminPortalStore = configureStore({
    reducer: adminPortalReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(portalApiSlice.middleware).concat(portalAuthenticationApiSlice.middleware),
    devTools: process.env.ENV !== PrizeoutEnv.PRODUCTION,
});

export type AdminPortalRootState = ReturnType<typeof _adminPortalRootReducer>;

export type AppDispatch = typeof adminPortalStore.dispatch & ThunkDispatch<AdminPortalRootState, null, AnyAction>;

export const AdminPortalInitialState: AdminPortalRootState = {
    ...commonInitState,
};
