import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { basePortalQuery } from '../common';
import {
    CashbackHistoryRequest,
    CashbackHistoryResponse,
    CashbackPrizeoutUserIdRequest,
    DashboardUserInfo,
    DashboardUserPermsInfo,
    NewDashboardUserInfo,
    NewSandboxAccountInfo,
    PartnerAccount,
    PartnerIntegrationDetails,
    PartnerPortalMutationResponse,
    PartnerRewardStatistics,
    PartnerRewardStatisticsArgs,
    PortalEventGroups,
    RewardSendReportQuery,
    RewardsUser,
    RewardsUsersQuery,
    SandboxAccount,
    UserCashbackBalancesResponse,
    UserCashbackSummaryResponse,
    UserRewards,
    UserRewardsQuery,
} from './partner-portal-types';
import { PartnerReward } from '../rewards';
import { PaginatedData, PaginationQuery } from '../../../utils';
import { CashbackTransaction } from '../cashback';

export const portalApiSlice = createApi({
    reducerPath: 'partnerPortalApi',
    baseQuery: basePortalQuery,
    tagTypes: [
        PortalEventGroups.DashboardUsersList,
        PortalEventGroups.PortalCashbackBalances,
        PortalEventGroups.PortalCashbackHistory,
        PortalEventGroups.PortalCashbackPendingHistory,
        PortalEventGroups.PortalCashbackSummary,
        PortalEventGroups.PortalIntegrationsDetails,
        PortalEventGroups.PortalRewardsSummaryData,
        PortalEventGroups.PortalRewardsStatistics,
        PortalEventGroups.PortalRewardsUsersList,
        PortalEventGroups.PortalRewardSendReport,
    ],
    endpoints: (builder) => ({
        activateSecurityTokenSecondary: builder.mutation<PartnerIntegrationDetails, void>({
            query: () => ({
                url: '/partner/integration/activateSecurityTokenSecondary',
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.PortalIntegrationsDetails],
        }),
        addDashboardUser: builder.mutation<PartnerPortalMutationResponse, NewDashboardUserInfo>({
            query: (newDashboardUserInfo) => ({
                url: '/partner/dashboardUser',
                method: 'POST',
                body: newDashboardUserInfo,
            }),
            invalidatesTags: [PortalEventGroups.DashboardUsersList],
        }),
        createSandboxAccount: builder.mutation<PartnerPortalMutationResponse, NewSandboxAccountInfo>({
            query: (newSandboxAccountInfo) => ({
                url: '/partner/sandbox/sandboxAccount',
                method: 'POST',
                body: newSandboxAccountInfo,
            }),
        }),
        deleteApiKeySecondary: builder.mutation<PartnerIntegrationDetails, void>({
            query: () => ({
                url: '/partner/integration/deleteApiKeySecondary',
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.PortalIntegrationsDetails],
        }),
        deleteDashboardUser: builder.mutation<PartnerPortalMutationResponse, DashboardUserInfo>({
            query: (dashboardUserInfo) => ({
                url: '/partner/dashboardAccount/delete',
                method: 'POST',
                body: dashboardUserInfo,
            }),
            invalidatesTags: [PortalEventGroups.DashboardUsersList],
        }),
        deleteSecretKeySecondary: builder.mutation<PartnerIntegrationDetails, void>({
            query: () => ({
                url: '/partner/integration/deleteSecretKeySecondary',
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.PortalIntegrationsDetails],
        }),
        deleteSecurityTokenSecondary: builder.mutation<PartnerIntegrationDetails, void>({
            query: () => ({
                url: '/partner/integration/deleteSecurityTokenSecondary',
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.PortalIntegrationsDetails],
        }),
        demoteApiKey: builder.mutation<PartnerIntegrationDetails, void>({
            query: () => ({
                url: '/partner/integration/demoteApiKey',
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.PortalIntegrationsDetails],
        }),
        demoteSecretKey: builder.mutation<PartnerIntegrationDetails, void>({
            query: () => ({
                url: '/partner/integration/demoteSecretKey',
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.PortalIntegrationsDetails],
        }),
        getCashbackBalancesList: builder.query<PaginatedData<UserCashbackBalancesResponse[]>, PaginationQuery>({
            query: (args: PaginationQuery) => {
                return {
                    url: '/partner/prizeoutUser/cashbackBalances',
                    params: args,
                };
            },
            providesTags: [PortalEventGroups.PortalCashbackBalances],
        }),
        getCashbackHistory: builder.query<PaginatedData<CashbackHistoryResponse[]>, CashbackHistoryRequest>({
            query: (args: CashbackHistoryRequest) => ({
                url: `/partner/prizeoutUser/${args.prizeoutUserId}/cashbackHistory`,
                params: {
                    currentPage: args.currentPage,
                    perPage: args.perPage,
                },
            }),
            providesTags: [PortalEventGroups.PortalCashbackHistory],
        }),
        getCashbackPendingHistory: builder.query<CashbackTransaction[], CashbackPrizeoutUserIdRequest>({
            query: (args: CashbackPrizeoutUserIdRequest) => ({
                url: `/partner/prizeoutUser/${args.prizeoutUserId}/pendingCashbackHistory`,
            }),
            providesTags: [PortalEventGroups.PortalCashbackPendingHistory],
        }),
        getCashbackSummary: builder.query<UserCashbackSummaryResponse, CashbackPrizeoutUserIdRequest>({
            query: (args: CashbackPrizeoutUserIdRequest) => ({
                url: `/partner/prizeoutUser/${args.prizeoutUserId}/cashbackSummary`,
            }),
            providesTags: [PortalEventGroups.PortalCashbackSummary],
        }),
        getDashboardUsersList: builder.query<PartnerAccount[], void>({
            query: () => ({
                url: '/partner/dashboardUser/list',
            }),
            providesTags: [PortalEventGroups.DashboardUsersList],
        }),
        getPartnerIntegrationDetails: builder.query<PartnerIntegrationDetails, void>({
            query: () => ({
                url: '/partner/integrationDetails',
            }),
            providesTags: [PortalEventGroups.PortalIntegrationsDetails],
        }),
        getPartnerRewardsList: builder.query<PartnerReward[], boolean>({
            query: (activeRewards?: boolean) => ({
                url: '/partner/rewards/list',
                params: { activeRewards },
            }),
            providesTags: [PortalEventGroups.PortalRewardsSummaryData],
        }),
        getPartnerRewardStatistics: builder.query<PartnerRewardStatistics, PartnerRewardStatisticsArgs>({
            query: (args: PartnerRewardStatisticsArgs) => {
                const query = args.query;

                return {
                    url: `/partner/rewards/rewardStatistic/${args.partnerRewardId}`,
                    params: query,
                };
            },
            providesTags: [PortalEventGroups.PortalRewardsStatistics],
        }),
        getRewardsUsersList: builder.query<PaginatedData<RewardsUser[]>, RewardsUsersQuery>({
            query: (args: RewardsUsersQuery) => {
                return {
                    url: '/partner/prizeoutUser',
                    params: args,
                };
            },
        }),
        getSandboxAccountList: builder.query<SandboxAccount[], void>({
            query: () => ({
                url: '/partner/sandbox/sandboxAccount',
            }),
        }),
        getUserCashbackSummary: builder.query<UserCashbackSummaryResponse, string>({
            query: (userId: string) => ({
                url: `partner/prizeoutUser/${userId}/cashbackSummary`,
            }),
        }),
        getUserRewardsList: builder.query<PaginatedData<UserRewards[]>, UserRewardsQuery>({
            query: (args: UserRewardsQuery) => {
                return {
                    url: '/partner/rewards/userSummary',
                    params: args,
                };
            },
        }),
        sendRewardReport: builder.mutation<PartnerPortalMutationResponse, RewardSendReportQuery>({
            query: (rewardSendReportQuery) => ({
                url: '/partner/dataExport/schedule/cashbackBalances',
                method: 'POST',
                body: rewardSendReportQuery,
            }),
            invalidatesTags: [PortalEventGroups.PortalRewardSendReport],
        }),
        regenerateSecurityTokenSecondary: builder.mutation<PartnerIntegrationDetails, void>({
            query: () => ({
                url: '/partner/integration/regenerateSecurityTokenSecondary',
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.PortalIntegrationsDetails],
        }),
        updateDashboardUserPerms: builder.mutation<PartnerPortalMutationResponse, DashboardUserPermsInfo>({
            query: (dashboardUserPermsInfo) => ({
                url: '/partner/dashboardAccount/perms/update',
                method: 'POST',
                body: dashboardUserPermsInfo,
            }),
            invalidatesTags: [PortalEventGroups.DashboardUsersList],
        }),
    }),
});

export const {
    useActivateSecurityTokenSecondaryMutation,
    useAddDashboardUserMutation,
    useCreateSandboxAccountMutation,
    useDeleteApiKeySecondaryMutation,
    useDeleteDashboardUserMutation,
    useDeleteSecretKeySecondaryMutation,
    useDeleteSecurityTokenSecondaryMutation,
    useDemoteApiKeyMutation,
    useDemoteSecretKeyMutation,
    useGetCashbackBalancesListQuery,
    useGetCashbackHistoryQuery,
    useGetCashbackPendingHistoryQuery,
    useGetCashbackSummaryQuery,
    useGetDashboardUsersListQuery,
    useGetPartnerIntegrationDetailsQuery,
    useGetPartnerRewardsListQuery,
    useGetPartnerRewardStatisticsQuery,
    useGetRewardsUsersListQuery,
    useGetSandboxAccountListQuery,
    useGetUserCashbackSummaryQuery,
    useGetUserRewardsListQuery,
    useSendRewardReportMutation,
    useRegenerateSecurityTokenSecondaryMutation,
    useUpdateDashboardUserPermsMutation,
} = portalApiSlice;
